:root {
    --text-color: #1D3557;
    --button-color: #A8DADC;
    --header-color: #F1FAEE;
}

body {
    padding: 0;
    margin-top: 0;
    margin-bottom: 100px;
}

/* mobile styles */
.content {
    margin-top: 100px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-align: center;
}

.timer-set {
    margin-top: 2.5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.controls {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-self: center;
}

.btn {
    height: 30px;
    width: 75%;
    align-self: center;
    justify-self: center;
    background-color: var(--button-color);
}

.quarter {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
}

.quarterControl {
    display: grid;
    grid-template-rows: 1fr 1fr ;
}

.score {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.card {
    height: 175px; 
}

.footer {
    align-items: center;
    justify-content: center;
}

.toolbar {
    display: flex;
    justify-content: space-between;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding: 10px;
}

.timer {
    margin-bottom: 10px;
}

.color-card {
    width: 110px;
    height: 65px;
    justify-content: center;
    align-items: center;
}

.colors {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.list {
    border-radius: 50%;
    border: solid black 2px;
    height: 20px;
    width: 20px;
    justify-self: center;
    align-self: center;
}

.list:hover {
    transform: scale(1.1)
}

/* small tablet styles & up*/

@media screen and (min-width: 600px){
    .content {
        margin-top: 100px;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        justify-content: center;
    }

    .controls {
        justify-self: center;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    
    .btn {
        height: 50px;
        width: 150px;
        align-self: center;
        justify-self: center;
    }
    
    .quarter {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        display: flex;
        justify-self: center;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        width: 200px;
    }

    .card {
        width: 100%;
        display: grid;
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: repeat(3, 1fr);
        height: 200px;
        justify-content: center;
        align-items: center;
    }

    .actions {
        grid-row: 1 / 2;
        grid-column: 1 / 4;
        display: flex;
        justify-content: space-between;
    }

    .score {
        grid-row: 2 / 4;
        grid-column: 2 / 3;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;

    }

    .teamName {
        width: 225px;
        height: 30px;
        justify-self: center;
        grid-row: 4 / 6;
        grid-column: 2 / 3;
    }
    
    .period {
        font-size: 30px;
    }

    .timer-set {
        display: grid;
        grid-template-columns: repeat(2, 200px);
        justify-content: center;
        margin: 10px;
    }

    .input {
        justify-self: center;
    }

    .add {
        width: 200px;
        height: 45px;
    }

    .items {
        justify-content: center;
        align-items: center;
    }

    .arrow {
        height: 100%;
        width: 100%;
    }

    .color-card {
        width: 150px;
        height: 65px;
        justify-content: center;
        align-items: center;
    }

    .colors {
        margin-top: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .list {
        border-radius: 50%;
        border: solid black 2px;
        height: 20px;
        width: 20px;
        justify-self: center;
        align-self: center;
    }

    .list:hover {
        transform: scale(1.1)
    }

    .timer {
        margin-bottom: 15px;
        padding: 0;
        width: 100%;
    }
    .accordion {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
}

